import React from 'react'
import Template from './template/Template'
import { Link, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import SecretcodeBox from "./template/images/secret-code-box.png";
const Index = () => {
  const page = new URLSearchParams(useLocation().search).get('page');
  console.log(page)
  return (
    <>
      {page === 'register-now' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              {/* <Template.Spinner /> */}
              <Template.Columns gutter="52px">
                <Template.Columns gutter="38px">
                  <Link><Template.Buttton label="Register now" type="block"> <i className="fa fa-chevron-right"></i> </Template.Buttton></Link>
                  <Template.Text type="small" theme="light" weight="bold" align="center"> How to participate, <a href="https://youtube.com/shorts/RG01X-1YCZ8?feature=share" target="_blank" > Click here<i className="fa fa-youtube-play"></i> </a></Template.Text>
                </Template.Columns>
                <Template.Text type="small" theme="secondary" align="center">
                  <b>For Queries call us on</b><br />
                  <a>040-2339 1221/2</a><a>040-4212999</a><br />
                  <b>Mail : </b><a><u>playandwin.@heritagefoods.in.</u></a>
                </Template.Text>
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'region' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              <Template.Columns>
                <Template.Select label="Region">
                  <i className="fa fa-chevron-down"></i>
                </Template.Select>
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'verify-mobile' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="22px">
                <Template.Input placeholder="Enter Full Name" />
                <Template.Input placeholder="Enter Mobile" />
                <Template.Input placeholder="Email Id" />
                <Template.Buttton label="Submit" className="align-self-center" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'verify-otp' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="42px">
                <Template.Columns gutter="37px">
                  <Template.Text type="h3" theme="secondary" weight="600" align="center">OTP Verification </Template.Text>
                  <Template.Text type="h6" theme="light" weight="500" align="center"> Enter OTP sent to <b>9676969175</b></Template.Text>
                </Template.Columns>
                <OtpInput
                  containerStyle="hfl:template:otp"
                  numInputs={4}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  renderInput={(props) => <input {...props} />}
                />
                <Template.Buttton label="Submit" className="align-self-center" />
                <Template.Text type="p" theme="light" weight="500" className="text-center" > Didn't Receive OTP?<a><b><u>Resend OTP</u></b></a>
                </Template.Text>
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'verify-secret-code' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="42px">
                <Template.Columns gutter="30px">
                  <Template.Text type="h3" theme="secondary" weight="600" align="center">Enter Secret Code</Template.Text>
                  <Template.Input />
                </Template.Columns>
                <img src={SecretcodeBox} className="img-fluid img-thumbnail" />
                <Template.Buttton label="Submit" className="align-self-center" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'quiz' &&
        <Template bg>
          <Template.Header />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="100px">
                <Template.Text type="h3" theme="secondary" weight="600" align="center">What is the Colour of Heritage Doodh Peda?</Template.Text>
                <Template.Quiz options={['Natural Gold', 'Orange', 'Green', 'Violet']} answer="Orange" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'gift' &&
        <Template bg>
          <Template.Header />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="37px">
                <Template.Text type="h3" theme="secondary" weight="600" align="center">Congratulations!</Template.Text>
                <Template.Text type="h6" theme="light" weight="500" align="center">You Have Won</Template.Text>
                <Template.Gift value={100} />
                <Template.Buttton className="align-self-center" onlyIcon><i className='fa fa-chevron-right'></i></Template.Buttton>
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'verify-upi' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="37px">
                <Template.Text type="h3" theme="secondary" weight="600" align="center">Congratulations!</Template.Text>
                <Template.Text type="h6" theme="light" weight="500" align="center">You Have Won</Template.Text>
                <Template.Input placeholder="Enter UPI ID" />
                <Template.Buttton label="Proceed" className="align-self-center" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'bank-details' &&
        <Template>
          <Template.Header />
          <Template.Badge />
          <Template.Body>
            <Template.Container>
            <Template.Columns gutter="22px">
                <Template.Input placeholder="Enter Your Name" />
                <Template.Input placeholder="Enter Bank Name" />
                <Template.Input placeholder="Enter IFSC Code" />
                <Template.Input placeholder="Enter Account Number" />
                <Template.Buttton label="Submit" className="align-self-center" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      {page === 'terms' &&
        <Template bg>
          <Template.Header />
          <Template.Body>
            <Template.Container>
              <Template.Columns gutter="37px">
                <Template.Card>
                  <Template.Editor>
                    <h5 className="mb-4">
                      <center>
                        <u>
                          TERMS AND CONDITIONS: HERITAGE GHEE SCAN AND WIN CONTEST
                        </u>
                      </center>
                    </h5>
                    <ol>
                      <li>
                        <h5>Eligibility:</h5>
                        <ol>
                          <li>
                            Heritage Ghee Scan and Win Contest (hereinafter referred
                            to as the "Contest") is open to all individuals who are
                            citizens of India with aged 18 years or above.
                          </li>
                          <li>
                            By participating in the Contest, Participants agree to
                            comply with these Terms and Conditions and all decisions
                            made by Heritage Foods Limited which are final and binding
                            in all aspects relating to the Contest.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>Offer Period:</h5>
                        <ol>
                          <li>
                            The Contest will run in two phases. The duration of the
                            promo & the eligible markets for each phase is as below -
                          </li>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">Phase</th>
                                  <th scope="col">Markets</th>
                                  <th scope="col">Promotion Starting Date & Time</th>
                                  <th scope="col">Promotion Ending Date & Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">1</th>
                                  <td>TS, AP, KA, MP, MH, Delhi NCR</td>
                                  <td>3rd. Sep 2024 9:00 AM</td>
                                  <td>30th. Nov 2024 11:59 PM</td>
                                </tr>
                                <tr>
                                  <th scope="row">2</th>
                                  <td>
                                    Kolkata, Bihar, Jharkhand, Chhattisgarh, Orissa
                                  </td>
                                  <td>20th. Sep 2024 9:00 AM</td>
                                  <td>30th. Nov 2024 11:59 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <li>
                            Entries will be received till stock lasts. Entries from
                            the participants will not be considered before
                            commencement of Contest and after the stocks are over.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>How to Participate:</h5>
                        <ol>
                          <li>
                            To participate in the Contest, participants must:
                            <ol type="a">
                              <li>
                                Purchase any of the following SKUs with a QR code on
                                the pack -
                                <ul>
                                  <li>Cow Ghee Jar 500ML</li>
                                  <li>Cow Ghee Jar 1000ML</li>
                                  <li>Danedar Ghee CEKA 500ML</li>
                                  <li>Danedar Ghee CEKA 1000ML</li>
                                </ul>
                              </li>
                              <li>
                                Scan the QR code present on any of the above-mentioned
                                Ghee packs using a compatible scanning application or
                                smartphone camera.
                              </li>
                              <li>
                                Lands on the promo microsite and get yourself
                                registered by providing accurate and complete
                                information of personal details.
                              </li>
                              <li>Mobile Number gets verified via an OTP.</li>
                            </ol>
                          </li>
                          <li>
                            Participants to enter the unique secret code. They will
                            have five chances to enter the right code. The secret code
                            location is highlighted by a “SECRET CODE HERE” mark on
                            the packaging. Pack-wise location of the secret code is as
                            below -
                            <ul>
                              <li>
                                Cow Ghee Jar 500ML & 1000ML - reverse of the label
                              </li>
                              <li>
                                Danedar Ghee CEKA 500ML & 1000ML - below the side flap
                              </li>
                            </ul>
                          </li>
                          <li>
                            Once the unique code is entered and verified, the
                            participants will find a simple multiple choice question
                            on the screen.
                          </li>
                          <li>
                            If the participants answer it correctly, it takes them to
                            the next page where one of the available denominations
                            will appear, in a randomized manner via the system. In
                            case the answer is incorrect, the participants will get a
                            chance to try a maximum of three times.
                          </li>
                          <li>
                            The participants can redeem the prize money by entering
                            their UPI ID and the money will be transferred to their
                            assigned account.
                          </li>
                          <li>
                            For denominations above Rs.10000, the participant will
                            need to submit their Pan Card and other details; the
                            payment will be made through NEFT/Cheque after deducting
                            of TDS.
                          </li>
                          <li>
                            For the Winners who have won cash prize ranging between Rs
                            25,000 to Rs1,00,000; The cash prize will be transferred
                            only after completion of KYC or Due Diligence Process.
                            Please contact Toll Free Number{" "}
                            <a href="tel:18004252931">1-800-425-2931</a>, or you can
                            write us on{" "}
                            <a href="mailto:playandwin.@heritagefoods.in">
                              playandwin.@heritagefoods.in.
                            </a>
                          </li>
                          <li>
                            The Participant phone number through which registration
                            was done to participate in the Contest and the UPI ID
                            Phone number of the participant should be one and same.
                          </li>
                          <li>
                            <b>
                              <u>
                                Heritage Foods Limited reserves the right to
                                disqualify any incomplete, inaccurate, or illegible
                                entries
                              </u>
                            </b>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>Prizes:</h5>
                        <ol>
                          <li>
                            The Cash Rewards are non-transferable and cannot be
                            exchanged with any other alternative.
                          </li>
                          <li>
                            Heritage Foods Limited reserves the right to substitute
                            the prizes with Other Prizes of equal or greater value
                            without prior notice.
                          </li>
                          <li>
                            The cash denominations to be won are as follows-
                            <ul>
                              <li>₹50</li>
                              <li>₹100</li>
                              <li>₹500</li>
                              <li>₹1,000</li>
                              <li>₹10,000</li>
                              <li>₹25,000</li>
                              <li>₹50,000</li>
                              <li>₹1,00,000</li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>Winner Selection:</h5>
                        <ol>
                          <li>
                            {" "}
                            The Winner selection will be based on the above process of
                            answering the multiple-choice questions.
                          </li>
                          <li>
                            If a Winner cannot be contacted within seven (7) days
                            after the initial notification (Once the winning amount is
                            displayed on the screen), fails to provide the required
                            information or is found to be ineligible, Heritage Foods
                            Limited reserves the right to disqualify that Winner.
                          </li>
                          <li>
                            The Winner must raise a complaint with respect to
                            non-transfer of winning amount within seven (7) working
                            days from the date of winning. For any Inquiries Contact
                            the Toll-free Number{" "}
                            <a href="tel:18004252931">1-800-425-2931</a> on the Pack
                            or Mail us at{" "}
                            <a href="mailto:playandwin.@heritagefoods.in">
                              playandwin.@heritagefoods.in
                            </a>
                            .
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>Publicity:</h5>
                        <ol>
                          <li>
                            By participating in the Contest, Winners agree to allow
                            Heritage Foods Limited to use their names, photographs,
                            testimonials, videos and any other relevant information
                            for promotional and marketing purposes without any further
                            compensation.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>Privacy:</h5>
                        <ol>
                          <li>
                            Participants' personal information will be handled in
                            accordance with applicable data protection laws.
                          </li>
                          <li>
                            <b>
                              <u>
                                Participants hereby give his/her consent to Heritage
                                Foods Limited that the personal data of the
                                participant provided by him/her can be used for this
                                Scan & Win Contest and other marketing promo
                                activities of the company.
                              </u>
                            </b>
                          </li>
                          <li>
                            <b>
                              <u>
                                I acknowledge that Heritage Foods Limited has the
                                right to use my personal data and willingly offer my
                                above consent.
                              </u>
                            </b>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>General Conditions:</h5>
                        <ol>
                          <li>
                            Heritage Foods Limited reserves the right to modify,
                            suspend or terminate the Contest at any time without prior
                            notice for any reason, including but not limited to
                            technical issues, or any event beyond its control.
                          </li>
                          <li>
                            Heritage Foods Limited reserves the right to disqualify
                            any participant who violates these Terms and Conditions or
                            engages in any fraudulent or inappropriate behaviour.
                          </li>
                          <li>
                            Participants agree to release, indemnify, and hold
                            Heritage Foods Limited, its affiliates, subsidiaries and
                            their respective officers, directors, employees and agents
                            harmless from any claims, losses, damages, or liabilities
                            arising out of or related to their participation in the
                            Contest or acceptance, use, or misuse of any prize.
                          </li>
                          <li>
                            These Terms and Conditions shall be governed by and
                            construed in accordance with Indian law.
                          </li>
                          <li>
                            Any disputes arising out of or in connection with the
                            Contest shall be subject to the exclusive jurisdiction of
                            the courts located in Hyderabad.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <p>
                      <b>
                        By participating in the Contest, participants confirm that
                        they have read, understood and agree to abide by these Terms
                        and Conditions.
                      </b>
                    </p>
                    <p align="center">
                       *** 
                    </p>
                  </Template.Editor>
                </Template.Card>
                <Template.Buttton label="Accept" className="align-self-center" />
              </Template.Columns>
            </Template.Container>
          </Template.Body>
          <Template.Footer />
        </Template>
      }
      
    </>
  )
}
export default Index