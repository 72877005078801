import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";

import Home from "./components/layouts/Home";
import isEmpty from "./utils/isEmpty";
import { setUserDetails } from "./actions/userActions";
import NotificationBar from "./components/NotificationBar/NotificationBar";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    authChecker();
  }, []);

  const authChecker = () => {
    let { trackTraceJWToken, rolePermissions } = localStorage;
    // Check for token
    if (trackTraceJWToken) {
      // Decode token and get user info and exp
      try {
        let decoded = jwt_decode(trackTraceJWToken);
        dispatch(setUserDetails(decoded));
        // Check token expiry
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime || isEmpty(rolePermissions)) {
          localStorage.removeItem("trackTraceJWToken");
          localStorage.removeItem("rolePermissions");
          localStorage.removeItem("userInfo");
          navigate("/");
        } else {
          console.log(
            `Auto-logout in ${Math.round(decoded.exp - currentTime)} seconds`
          );
        }
      } catch (error) {
        navigate("/");
      }
    }
  };

  const userFrom = localStorage.getItem("isLoggedInFrom");

  return (
    <div className="App">
      <Home />
      {!isEmpty(userFrom) && userFrom === "Admin" && <NotificationBar />}
    </div>
  );
}

export default App;
