import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Routes from "../../Routings";
import isEmpty from "../../utils/isEmpty";
import Loader from "./Loader";

export default function Home() {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const { pathname } = location;

  let paths = [
    "/404",
    "/",
    "/login",
    "/signup",
    "/forgotpassword",
    "/v",
    "/verifiedproductdetails",
    "/product-details",
    "/product-journey",
    "/verifymobilenumber",
    "/verifyotpcode",
    "/personal-details",
    "/verify-unique-number",
    "/answer-and-win",
    "/payment",
    "/product-details-verified-reward",
    "/changepassword",
    "/manufacturer-login-reset-password",
    "/verify-message",
    "/congrats-message",
    "/reward-confirm",
    "/terms-and-conditions",
    "/howto-participate",
    "/share-reward",
    "quiz-questions",
    "/product-locations",
    "/verify-phoneno",
  ];
  let loginPaths = ["/", "/login", "/signup", "/forgotpassword"];

  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (!isEmpty(userInfo)) {
    if (loginPaths.indexOf(pathname) >= 0) {
      return <Navigate to="/dashboard" replace />;
    }
  }

  return (
    <>
      {user.isLoading === true ? <Loader /> : ""}
      <div>
        <ToastContainer />
        {!paths.includes(pathname) ? (
          <>
            {!isEmpty(userInfo) && <Navbar />}
            {/*NAVBAR*/}
            <div className={userInfo ? "t-container" : ""}>
              {/*================== NAV ======================*/}
              {!isEmpty(userInfo) && <Sidebar />}
              {/*================== NAV ======================*/}
              <Routes />
            </div>
          </>
        ) : (
          <Routes />
        )}
      </div>
    </>
  );
}
